<template>
	<v-row justify="center">
		<MySnackbar
			v-model="alert.show"
			:type="alert.type"
			:text="alert.text"
		/>
		<v-dialog ref="dialog" v-model="modal" width="600px">
			<v-card class="text-center">
				<v-card-text>
					<v-avatar size="64" class="my-6 ico-shadow">
						<img
								:src="require('@/assets/logo.png')"
								alt="Kicket"
						>
					</v-avatar>
					<h2 class="mb-3">
						درباره کیکت
					</h2>
					سامانه مدیریت و استعدادیابی فوتبال جهت ارتقاء سطح ورزش کشور در سال 1398 بنا شده است . کشف استعداد های برتر فوتبال در سنین پایین و معرفی این استعداد ها به تیم های مطرح کشور از اهداف اصلی این سامانه می باشد همچنین این سامانه جهت ایجاد تعامل بیشتر و ساماندهی ارتباطات بین بازیکنان و مربیان جهت پیشرفت هرچه بیشتر بازیکنان نیز اقداماتی کرده است.
				</v-card-text>
			</v-card>
		</v-dialog>
	</v-row>
</template>

<script>
import { Constants } from "../constants/GlobalConstants";
import VFormBase from "vuetify-form-base";
import MyAxios from "../constants/MyAxios";
import MySnackbar from "../components/MySnackbar";

export default {
	components: { VFormBase, MySnackbar },
	data() {
		return {
			modal: false,
			loading: false,
			alert: {
				show: false,
				text: "",
				type: "error",
			},
			desc: {
				value: "",
				show: false,
			},
			rules: Constants.rules,
		};
	},
	props: {
		// value: {
		// 	// v-model instance
		// 	type: String,
		// 	required: true,
		// },
		withModel: {
			type: Boolean,
			default: true,
		},
		withButton: {
			type: Boolean,
			default: true,
		},
	},
	methods: {
		onOkClick() {
			if (this.$refs.form.validate()) {
				// if (this.withModel) this.$emit("input", this.newpassword.value);
				// else this.PostNewPassword();
				this.postReport();
			}
		},
		onChangeBtnClick() {
			this.modal = true;
		},
		onClose() {
			this.modal = false;
		},
	},
	computed: {},
};
</script>

<style scoped>
.ico-shadow {
	box-shadow: 0 0 0px 7px #cbcbcb, 0 0 4px 13px #dcdcdc;
}
</style>
